import http from '@/utils/http';
import type * as Request from './interface/request';
import type * as Response from './interface/response';

const modulePath = '/list';

/**
 * @description: 猜你喜欢
 * @param {Request} params
 * @return {*}
 */
export function getGoodsRecommend(params: Request.GoodsRecommend) {
  return http.client<Response.GoodsRecommendList>(`${modulePath}/get_goods_recommend`, {
    params,
  });
}

/**
 * @description: 商品详情页
 * @param {number} goodsId
 * @return {*}
 */
export function getGoodsDetail(goodsId: number) {
  return http.client<Response.GoodsDetail>(`${modulePath}/get_one_goods?goodsId=${goodsId}`);
}

export default {};
