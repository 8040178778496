<template>
    <div class="swipe-wrapper">
        <button :class="['btn', 'left', {'active':lBtnActive}]" @click="btnFunc('left')">
            <svg width="1em" height="1em" fill="#D8D8D8" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3839">
            <path d="M512 0C229.221434 0 0 229.221434 0 512S229.221434 1024 512 1024 1024 794.778566 1024 512 794.778566 0 512 0zM512 985.358491C250.570868 985.358491
            38.641509 773.429132 38.641509 512 38.641509 250.570868 250.570868 38.641509 512 38.641509 773.429132 38.641509 985.358491 250.570868 985.358491 512 985.358491
            773.429132 773.429132 985.358491 512 985.358491zM408.788528 516.946113 605.976151 319.758491c3.767547-3.767547 3.767547-9.892226
            0-13.659774l-13.659774-13.659774c-3.767547-3.767547-9.892226-3.767547-13.659774 0L360.061585 511.033962c-3.767547 3.767547-3.767547 9.892226 0 13.659774l13.659774
            13.659774c1.333132 1.333132 2.994717 2.028679 4.714264 2.415094l209.011925 208.992604c3.728906 3.748226 9.795623 3.748226 13.543849 0l13.543849-13.543849c3.728906-3.728906
            3.728906-9.795623 0-13.543849L408.788528 516.946113z" p-id="3840"/>
            </svg>
        </button>
        <button :class="['btn', 'right', {'active':rBtnActive}]" @click="btnFunc('right')">
            <svg width="1em" height="1em" fill="#D8D8D8" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="676">
            <path d="M512 1024C794.778566 1024 1024 794.778566 1024 512S794.778566 0 512 0 0 229.221434 0 512 229.221434 1024 512 1024zM512 38.641509C773.429132 38.641509 985.358491
            250.570868 985.358491 512 985.358491 773.429132 773.429132 985.358491 512 985.358491 250.570868 985.358491 38.641509 773.429132 38.641509 512 38.641509 250.570868 250.570868
            38.641509 512 38.641509zM615.211472 507.053887L418.023849 704.241509c-3.767547 3.767547-3.767547 9.892226 0 13.659774l13.659774 13.659774c3.767547 3.767547 9.892226 3.767547
            13.659774 0L663.938415 512.966038c3.767547-3.767547 3.767547-9.892226 0-13.65977399l-13.659774-13.659774c-1.333132-1.333132-2.994717-2.028679-4.714264-2.41509401l-209.01192499-208.992604c-3.728906-3.748226-9.795623-3.748226-13.54384902 0l-13.54384899 13.543849c-3.728906 3.728906-3.728906 9.795623
            0 13.543849L615.211472 507.053887z" p-id="677"/>
            </svg>
        </button>
        <ul class="content">
            <li v-for="(img, index) in swipeOptions" :key="img">
                <img :src="`https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/grade/${img}`" alt="swipe image" v-show="index===n">
            </li>
        </ul>
        <h3>{{bottomTitle?bottomTitle:''}}</h3>
    </div>
</template>
<script lang='ts'>
import {
  defineComponent, onMounted, reactive, ref,
} from 'vue';

export default defineComponent({
  name: 'Swipe',
  props: {
    swipeOptions: {
      required: true,
      type: Array,
    },
    bottomTitle: {
      type: String,
    },
  },
  setup(props) {
    const n = ref(0);
    const rBtnActive = ref(true);
    const lBtnActive = ref(false);
    const imgs: string[] = reactive([]);
    const loading = ref(true);
    const btnFunc = (value: string) => {
      if (value === 'left') {
        rBtnActive.value = false;
        lBtnActive.value = true;
        n.value = n.value === 0 ? props.swipeOptions.length - 1 : n.value - 1;
      } else {
        lBtnActive.value = false;
        rBtnActive.value = true;
        n.value = n.value === props.swipeOptions.length - 1 ? 0 : n.value + 1;
      }
    };
    const preload = () => { // 轮播图预加载
      (props.swipeOptions as string[]).forEach((item) => {
        const img = new Image();
        img.src = item;
        img.onload = () => {
          loading.value = false;
          imgs.push(item);
        };
      });
    };
    onMounted(() => {
      preload();
    });
    return {
      n,
      rBtnActive,
      lBtnActive,
      btnFunc,
      imgs,
      loading,
    };
  },
});
</script>
<style lang='scss' scoped>
@import '@/assets/styles/mixin.scss';
@import '@/assets/styles/variables.scss';

@keyframes img-swipe {
    0% {
        transform: scale3d(0.98, 0.98, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
};
.swipe-wrapper{
    position: relative;
    @include flex-center();
    flex-direction: column;
    margin-top: 44px;
    .content{
        display: flex;
        width: 455px;
        height: 574px;
         @media screen and (max-width:1800px){
            width: 355px;
            height: 374px;
        }
        overflow: hidden;
        align-items: center;
        :deep(.loading) {
            background: inherit;
            margin-left: -109px;
        }
        li{
            list-style: none;
            img{
                width: 100%;
                animation: img-swipe 800ms;
            }
        }
    }
    .btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: inherit;
        border: none;
        outline: none;
        cursor: pointer;
        svg{
            width: 50px;
            height: 50px;
            // transition: opacity 250ms;
        }
        &.left{
            right: 100%;
            margin-right: 110px;
        }
        &.right{
            left: 100%;
            margin-left: 110px;
        }
        &.active{
            svg{
                fill: $theme-color;
            }
        }
    }
    h3{
        font-size: 33px;
        font-weight: 500;
        color: #000000;
        line-height: 33px;
        margin-top: 30px;
        margin-bottom: 44px;
    }
}
</style>
