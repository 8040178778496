
import { defineComponent } from 'vue';
import RegionWrapper from './RegionWrapper.vue';

export default defineComponent({
  name: 'PopAlert',
  components: {
    RegionWrapper,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    closeOnClickOverlay: { // 设置点击遮罩层关闭
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const close = () => {
      ctx.emit('update:visible', false);
      document.body.style.overflow = 'auto'; // 弹框消失 页面恢复可以滚动状态
    };
    const onClickOverlay = () => {
      if (props.closeOnClickOverlay) {
        close();
      }
    };
    return {
      close,
      onClickOverlay,
    };
  },
});
