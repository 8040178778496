<template>
  <Carousel
    class="goods-pciture-carousel"
    arrows
    dots-class="slick-dots slick-thumb"
    ref="carousel"
    :infinite="false"
  >
    <template #customPaging="props">
      <img :src="`${images[props.i]}?x-oss-process=image/resize,w_60,h_60`" />
    </template>
    <img :src="item" v-for="item in images" :key="item" />
  </Carousel>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { Carousel } from 'ant-design-vue';
import {
  computed,
  defineComponent, ref, watchEffect,
} from 'vue';

type ColorPictures = {
  keys: string[];
  values: string[];
};

type CarouselRef = {
  getDotPosition(): 'bottom' | 'left' | 'right' | 'top';
  saveSlick(node: HTMLElement): void;
  onWindowResized(): void;
  next(): void;
  prev(): void;
  goTo(slide: number, dontAnimate?: boolean): void;
};

export default defineComponent({
  name: 'DetailCarousel',
  components: {
    Carousel,
    ...importComponents(Carousel),
  },
  props: {
    album: {
      required: true,
      type: Array as () => string[],
    },
    albumOfColor: {
      type: Object as () => Record<string, string>,
      default: {} as Record<string, string>,
    },
    cover: String,
    value: String,
  },
  setup(props) {
    const carousel = ref<CarouselRef>();

    const images = computed(() => {
      const list = [];
      if (props.cover) {
        list.push(props.cover);
      }
      if (props.album?.length) {
        list.push(...props.album.filter((a) => a !== ''));
      }
      const colorPictures = Object.values(props.albumOfColor);
      if (colorPictures.length) {
        colorPictures.forEach((item) => {
          if (item !== '') {
            list.push(item);
          }
        });
      }
      return list;
    });

    watchEffect(() => {
      if (props.value) {
        const pic = props.albumOfColor[props.value];
        const index = images.value.indexOf(pic);
        if (carousel.value) {
          carousel.value.goTo(index, true);
        }
      }
    });

    return {
      images,
      carousel,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";
.goods-pciture-carousel {
  .slick-arrow {
    display: none !important;
  }
  .slick-list {
    margin: 0;
  }
  .slick-thumb {
    height: unset;
    position: relative;
    top: 40px;
    li {
      width: 60px;
      height: 60px;
      margin: 0 13px 16px;
      font-size: 0;
      vertical-align: middle;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;

      }
    }
  }
  .slick-slide {
    height: 605px;
    > div {
      width: 100%;
      font-size: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px 0;
      height: 100%;
      >img{
        width: unset !important;
        max-width: 100%;
        height: 100%;
      }
    }
  }
}

// & :deep(.slick-dots) {
//     position: relative;
//     height: auto;
// }
// & :deep(.slick-slide) {
//     img {
//         display: block;
//         margin: auto;
//         width: 100%;
//     }
// }
// & :deep .slick-thumb li.slick-active img {
//     filter: grayscale(0%);
// }
// .slick-slider.slick-initialized {
//     .slick-list {
//         margin-bottom: 35px;
//     }
// }
</style>
