const gradeImgList = [
  {
    categoryName: 'Apple',
    gradeImgs: [
      {
        grade: 'Excellent',
        imgs: [
          'excellent/iphone/iphone1.jpeg',
          'excellent/iphone/iphone2.jpeg',
          'excellent/iphone/iphone3.jpeg',
          'excellent/iphone/iphone4.jpeg',
          'excellent/iphone/iphone5.jpeg',
        ],
      },
      {
        grade: 'Fair',
        imgs: [
          'fair/iphone/iphone1.jpeg',
          'fair/iphone/iphone2.jpeg',
          'fair/iphone/iphone3.jpeg',
          'fair/iphone/iphone4.jpeg',
          'fair/iphone/iphone5.jpeg',
          'fair/iphone/iphone6.jpeg',
        ],
      },
      {
        grade: 'Good',
        imgs: [
          'good/iphone/iphone1.jpeg',
          'good/iphone/iphone2.jpeg',
          'good/iphone/iphone3.jpeg',
          'good/iphone/iphone4.jpeg',
        ],
      },
    ],
  },
  {
    categoryName: 'Samsung',
    gradeImgs: [
      {
        grade: 'Excellent',
        imgs: [
          'excellent/samsung/samsung1.jpeg',
          'excellent/samsung/samsung2.jpeg',
          'excellent/samsung/samsung3.jpeg',
          'excellent/samsung/samsung4.jpeg',
        ],
      },
      {
        grade: 'Fair',
        imgs: [
          'fair/samsung/samsung1.jpeg',
          'fair/samsung/samsung2.jpeg',
          'fair/samsung/samsung3.jpeg',
          'fair/samsung/samsung4.jpeg',
          'fair/samsung/samsung5.jpeg',
          'fair/samsung/samsung6.jpeg',
          'fair/samsung/samsung7.jpeg',
        ],
      },
      {
        grade: 'Good',
        imgs: [
          'good/samsung/samsung1.jpeg',
          'good/samsung/samsung2.jpeg',
          'good/samsung/samsung3.jpeg',
          'good/samsung/samsung4.jpeg',
          'good/samsung/samsung5.jpeg',
          'good/samsung/samsung6.jpeg',
        ],
      },
    ],
  },
];
export default gradeImgList;
