<template>
    <template v-if="visible">
        <teleport to='body'>
            <region-wrapper class="pop-content" @click="onClickOverlay">
                <slot/>
            </region-wrapper>
        </teleport>
    </template>
</template>
<script lang='ts'>
import { defineComponent } from 'vue';
import RegionWrapper from './RegionWrapper.vue';

export default defineComponent({
  name: 'PopAlert',
  components: {
    RegionWrapper,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    closeOnClickOverlay: { // 设置点击遮罩层关闭
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const close = () => {
      ctx.emit('update:visible', false);
      document.body.style.overflow = 'auto'; // 弹框消失 页面恢复可以滚动状态
    };
    const onClickOverlay = () => {
      if (props.closeOnClickOverlay) {
        close();
      }
    };
    return {
      close,
      onClickOverlay,
    };
  },
});
</script>
<style lang='scss' scoped>
@import '@/assets/styles/mixin.scss';
@import '@/assets/styles/variables.scss';

@keyframes pop-open {
    0% {
        transform: scale3d(0, 0, 1);
    }
    100% {
       transform: scale3d(1, 1, 1);
    }
};
.pop-content{
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.67);
    border: none;
    z-index: 100;
    :deep(.region-content){
        margin: 0;
        animation: pop-open 500ms;
        @media screen and (max-width:1800px){
            width: 880px;
        }
    }
}
</style>
