
import importComponents from '@/utils/import-components';
import { Carousel } from 'ant-design-vue';
import {
  computed,
  defineComponent, ref, watchEffect,
} from 'vue';

type ColorPictures = {
  keys: string[];
  values: string[];
};

type CarouselRef = {
  getDotPosition(): 'bottom' | 'left' | 'right' | 'top';
  saveSlick(node: HTMLElement): void;
  onWindowResized(): void;
  next(): void;
  prev(): void;
  goTo(slide: number, dontAnimate?: boolean): void;
};

export default defineComponent({
  name: 'DetailCarousel',
  components: {
    Carousel,
    ...importComponents(Carousel),
  },
  props: {
    album: {
      required: true,
      type: Array as () => string[],
    },
    albumOfColor: {
      type: Object as () => Record<string, string>,
      default: {} as Record<string, string>,
    },
    cover: String,
    value: String,
  },
  setup(props) {
    const carousel = ref<CarouselRef>();

    const images = computed(() => {
      const list = [];
      if (props.cover) {
        list.push(props.cover);
      }
      if (props.album?.length) {
        list.push(...props.album.filter((a) => a !== ''));
      }
      const colorPictures = Object.values(props.albumOfColor);
      if (colorPictures.length) {
        colorPictures.forEach((item) => {
          if (item !== '') {
            list.push(item);
          }
        });
      }
      return list;
    });

    watchEffect(() => {
      if (props.value) {
        const pic = props.albumOfColor[props.value];
        const index = images.value.indexOf(pic);
        if (carousel.value) {
          carousel.value.goTo(index, true);
        }
      }
    });

    return {
      images,
      carousel,
    };
  },
});
