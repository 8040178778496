
import DetailCarousel from '@/components/goodsdetail/DetailCarousel.vue';
import importComponents from '@/utils/import-components';
import {
  computed,
  defineComponent, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { Button, Divider } from 'ant-design-vue';
import { Alert } from '@/components/Alert/index';
import SkuSelector from '@/components/SkuSelector/SkuSelector.vue';
import IndexGoodsCard from '@/components/index/IndexGoodsCard.vue';
import { getGoodsDetail, getGoodsRecommend } from '@/services/goods-detail';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import type { Attrs, GoodsRecommend } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
import { AddCart } from '@/services/interface/request';

type SelectValue = {
  target: {
    value: string;
  };
}

interface Info {
  album: string[];
  cover: string;
  categoryName: string;
  descriptions: string[];
  skuAttrs: Attrs[];
  albumOfColor: Record<string, string>;
}

export default defineComponent({
  name: 'GoodsDetail',
  components: {
    DetailCarousel,
    SkuSelector,
    IndexGoodsCard,
    Load,
    ...importComponents(
      Divider,
      Button,
    ),
  },
  setup() {
    const { t } = useI18n();

    const route = useRoute();

    /** 当前商品的 SKU ID */
    const sid = computed(() => route.query.s as string);
    /** 当前商品的 ID */
    const gid = computed(() => route.params.goodsId as string);

    const { state, dispatch } = useStore();

    // 从 SKU Selector 组件中拿的选择数据
    const selectedSkuAttr = ref<Record<string, string>>({
      color: '',
      operador: '',
      capacidad: '',
      condición: '',
    });

    const selectedSkuValues = computed(() => {
      const source: string[] = [];
      const text: string[] = [];
      Object.entries(selectedSkuAttr.value).forEach(([key, value]) => {
        if (value) {
          source.push(value);
        }
        text.push(t(`attrs.value.${key}.${value}`));
      });
      return {
        source: source.join(' ').trim(),
        text: text.join(' '),
      };
    });

    const info = reactive<Info>({
      album: [],
      albumOfColor: {},
      cover: '',
      categoryName: '',
      descriptions: [],
      skuAttrs: [],
    });

    const recommendList = ref<GoodsRecommend[]>([]);

    const explanation = [
      {
        title: t('goodsDetail.explanation.title.t1'),
        p: t('goodsDetail.explanation.p.p1'),

      },
      {
        title: t('goodsDetail.explanation.title.t2'),
        p: t('goodsDetail.explanation.p.p2'),

      },
      {
        title: t('goodsDetail.explanation.title.t3'),
        p: t('goodsDetail.explanation.p.p3'),
      },
    ];

    const disabled = ref(false);

    const loading = ref(true);

    const getGoodsRecommendList = async (category: number) => {
      const params = {
        categoryId: category,
        pageNum: 1,
        pageSize: 10,
      };
      const res = await getGoodsRecommend(params);
      if (res.success) {
        recommendList.value = res.data.list;
      }
    };

    const goodsInfo = reactive({});

    const selectedPrice = reactive({
      marketPrice: 0,
      sellPrice: 0,
    });

    const skuMap = new Map();

    const detail = async () => {
      const res = await getGoodsDetail(parseInt(gid.value, 10));
      const { success, data } = res;
      if (!success || !data || data.skuList.length === 0) return;
      Object.assign(goodsInfo, data);
      const attrs: Attrs[] = [];
      // 每个地区后台返回来的数据顺序都不一样，让颜色图片排第一
      data.attrs.forEach((attr) => {
        if (attr?.value[0]?.picture !== undefined) {
          attrs.unshift(attr);
          return;
        }
        attrs.push(attr);
      });
      info.skuAttrs = attrs;
      info.categoryName = data.categoryName;

      // 设置 category
      getGoodsRecommendList(data.categoryId);

      /**
             * 颜色图片数据
             * hzgotb
             * 2021-02-27 21:05
             */
      const colors = info.skuAttrs.find((attr) => attr.name === 'color');
      if (colors) {
        info.albumOfColor = colors.value.reduce((result, color) => {
          Reflect.set(result, color.labelValue, color.picture);
          return result;
        }, {} as Record<string, string>);
      }

      info.album = data.goodsAlbum?.split(',');
      info.cover = data.goodsPic;
      info.descriptions = data.description?.split('\n');
      // 找到默认的 SKU
      const defaultSKU = data?.skuList.find((item) => item.skuId === parseInt(sid.value, 10)) || data?.skuList[0];
      if (defaultSKU) {
        Object.entries(defaultSKU.goodsAttr).forEach(([key, value]) => {
          selectedSkuAttr.value[key] = value;
        });
        // 初始化价格
        selectedPrice.marketPrice = defaultSKU.marketPrice;
        selectedPrice.sellPrice = defaultSKU.sellPrice;
      }
      data.skuList.forEach((item) => {
        const attribute = Object.values(item.goodsAttr).join(' ');
        const {
          skuId, marketPrice, sellPrice, realityStock,
        } = item;
        skuMap.set(attribute, {
          skuId, marketPrice, sellPrice, realityStock,
        });
      });
      loading.value = false;
    };

    watch(selectedSkuValues, (newValue) => {
      const { source } = newValue;
      if (!skuMap.get(source) || !skuMap.get(source).realityStock) {
        // Loading.loading();
        disabled.value = true;
      } else {
        selectedPrice.marketPrice = skuMap.get(source).marketPrice;
        selectedPrice.sellPrice = skuMap.get(source).sellPrice;
        disabled.value = false;
      }
    });

    const addToCart = () => {
      const { source } = selectedSkuValues.value;
      if (skuMap.get(source)) {
        const addSkuId = skuMap.get(source).skuId;
        if (!Cookies.get('token')) {
          // 如果是游客加购，判断本地存的购物车数量有没有超过库存量
          const inCartSku = (state.ShoppingCart as AddCart[]).find((item) => item.skuId === addSkuId);
          if (inCartSku && inCartSku.quantity + 1 > skuMap.get(source).realityStock) {
            const modal = Alert.error(t('shipping.overstockSku'));
            setTimeout(() => modal.destroy(), 2000);
            return;
          }
        }
        dispatch('ADDTOCART', {
          goodsId: parseInt(gid.value, 10),
          skuId: skuMap.get(source).skuId,
          realityStock: skuMap.get(source).realityStock,
          quantity: 1,
        });
        dispatch('changeCartShake').then(() => {
          disabled.value = true;
          setTimeout(() => {
            dispatch('changeCartShake');
            disabled.value = false;
          }, 2000);
        });
      }
    };

    onBeforeMount(() => {
      detail();
    });

    watch(sid, () => {
      if (sid.value) {
        detail();
      }
    });

    return {
      info,
      explanation,
      recommendList,
      getGoodsRecommendList,
      selectedSkuValues,
      disabled,
      selectedSkuAttr,
      goodsInfo,
      addToCart,
      selectedPrice,
      skuMap,
      loading,
      state,
      sid,
      t,
    };
  },
});
