/*
 * @Author: your name
 * @Date: 2020-12-29 11:01:31
 * @LastEditTime: 2020-12-29 11:21:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /web-pc/src/components/SkuSelector/mock.ts
 */
import type { Attribute } from './interface';

const mock: Attribute[] = [
  {
    name: 'Carrier',
    values: [
      {
        icon: '',
        label: 'Locked',
      },
      {
        icon: '',
        label: 'Generic',
      },
    ],
  },
  {
    name: 'Storage',
    values: [
      {
        icon: '',
        label: '64GB',
      },
      {
        icon: '',
        label: '128GB',
      },
      {
        icon: '',
        label: '256GB',
      },
    ],
  },
  {
    name: 'Color',
    values: [
      {
        icon: '/images/detail/goods1.png',
        label: 'Black',
      },
      {
        icon: '/images/detail/goods.png',
        label: 'Golden',
      },
      {
        icon: '/images/detail/goods5.png',
        label: 'White',
      },
    ],
  },
  {
    name: 'Grade',
    values: [
      {
        icon: '',
        label: 'Fair',
      },
      {
        icon: '',
        label: 'Good',
      },
      {
        icon: '',
        label: 'Excellent',
      },
    ],
  },
];

export default mock;
