<template>
    <div class="sku-selector">
        <div v-for="option in options" :key="option.name" class="container">
            <div class="name">
                <span class="label">{{ option.name }}:</span>
                <span class="selected">{{ value[option.name] }}</span>
                <span class="grade" @click="gradeImgToggle" v-if="option.name==='grade'">
                    <img src="../../assets/icons/what.png" alt="goods grade">
                    <i class="faq">FAQ</i>
                </span>
            </div>
            <div>
                <a-button
                    v-for="v in option.value"
                    :key="v.labelValue"
                    @click="() => onClick(option.name, v.labelValue)"
                    :class="{'active': value[option.name] === v.labelValue}"
                >
                    <template #icon v-if="v.picture">
                        <img :src="v.picture" alt="goodsIcon"/>
                    </template>
                    {{ v.labelValue }}
                </a-button>
            </div>
        </div>
    </div>
    <!-- 点击商品等级弹出等级图片弹框 -->
    <pop-alert v-model:visible="gradeImgVisible">
        <swipe :swipeOptions="imgs" :bottomTitle="gradeImgName"/>
    </pop-alert>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash';
import type { Attrs } from '@/services/interface/response';
import { Button, Modal } from 'ant-design-vue';
import {
  computed,
  defineComponent, ref,
} from 'vue';
import { Alert } from '@/components/Alert/index';
import { useI18n } from 'vue-i18n';
import mock from './mock';
import PopAlert from '../common/PopAlert.vue';
import Swipe from '../common/Swipe.vue';
import gradeImgList from './gradeImgs';

export interface Selected {
  [title: string]: string;
}

export default defineComponent({
  components: {
    PopAlert,
    Swipe,
    [Button.name]: Button,
    [Modal.name]: Modal,
  },
  props: {
    options: {
      required: true,
      type: Array as () => Attrs[],
    },
    value: {
      required: true,
      type: Object as () => Record<string, string>,
    },
    categoryName: {
      type: String,
    },
  },
  setup(props, ctx) {
    const { t } = useI18n();
    const gradeImgName = ref(props.value.grade); // 等级图等级
    const toUpperCase = (label: string) => label.replace(/^(\w)/, (word) => word.toUpperCase());

    const onClick = (name: string, selected: string) => {
      const newValue = cloneDeep(props.value);
      // TODO 修改对应的值
      newValue[name] = selected;
      ctx.emit('update:value', newValue);
      // eslint-disable-next-line no-unused-expressions
      name === 'grade' ? gradeImgName.value = selected : ''; // 设置等级轮播图标题
    };

    // 商品等级图弹框
    const gradeImgVisible = ref(false);

    // 等级图显示
    const imgs = computed(() => {
      const categoryImgs = gradeImgList.filter((item) => item.categoryName === props.categoryName)[0];
      const gradeImgs = categoryImgs.gradeImgs.filter((i) => i.grade === gradeImgName.value)[0];
      if (!gradeImgs) {
        return undefined;
      }
      return gradeImgs.imgs;
    });

    const gradeImgToggle = () => {
      if (!imgs.value) { // 解决没有选中grade时点击等级图按钮 弹框不弹出；
        const modal = Alert.error('Please choose grade');
        setTimeout(() => {
          modal.destroy();
        }, 2000);
        return;
      }
      gradeImgVisible.value = !gradeImgVisible.value;
      document.body.style.overflow = 'hidden';// 弹框出现 底部页面不可以滚动
    };

    return {
      mock,
      onClick,
      toUpperCase,
      gradeImgVisible,
      gradeImgToggle,
      imgs,
      gradeImgName,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import '@/assets/styles/variables.scss';

.sku-selector {
    padding-bottom: 20px;
    .container {
        padding-bottom: 10px;
        .name {
            font-size: 15px;
            font-weight: 500;
            color: $font-color-weak;
            line-height: 24px;
            padding-bottom: 5px;
            .label {
                text-transform: capitalize;
                padding-right: 12px;
            }
            .selected {
                font-weight: $font-weight;
                color: $font-color;
            }
            .grade{
                margin-left: 16px;
                cursor: pointer;
                &:hover{
                    .faq{
                    color:$theme-color;
                    }
                }
                img{
                    width: 15px;
                    height: 15px;
                    margin-right: 4px;
                    margin-bottom: 4px;
                }
                .faq{
                text-decoration: underline;
                font-style: normal;
                font-size: 12px;
                font-weight: 500;
                color: rgba(28, 28, 28, 0.5);
                line-height: 15px;
                transition: all 500ms;
                }
            }
        }
        .active {
            color: $theme-color;
            border-color: $theme-color;
        }
        .ant-btn {
            font-size: 14px;
            font-weight: 400;
            color: $font-color;
            &:hover,
            &:focus {
                border-color: $theme-color;
            }
            &:first-of-type ~ .ant-btn {
                margin-left: 21px;
            }
            &:nth-child(6), &:nth-child(7) {
                margin: 10px 0 0!important;
            }
            img {
                width: 18.5px;
                height: 20.5px;
                margin-right: 6px;
            }
        }
    }
}
</style>
