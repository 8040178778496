<template>
    <Load :loading="loading" v-if="loading"/>
    <div class="detail-container" v-else>
        <div class="top">
            <div class="left-image">
                <detail-carousel
                    :value="selectedSkuAttr.color"
                    :albumOfColor="info.albumOfColor"
                    :album="info.album"
                    :cover="info.cover"
                    :key="sid"/>
            </div>
            <div class="right-standard">
                <div class="above">
                    <div class="goods-name">{{goodsInfo.goodsName}}</div>
                    <!-- <div class="chose">{{selectedSkuValues.text}}</div> -->
                    <div class="price">
                        <span>{{t('goodsDetail.price')}}&nbsp;&nbsp;</span>
                        <span class="current-price">{{t('global.currency')}}{{selectedPrice.sellPrice}}&nbsp;&nbsp;</span>
                        <span class="original-price">{{t('global.currency')}}{{selectedPrice.marketPrice}}</span>
                    </div>
                    <a-divider/>
                    <SkuSelector :options="info.skuAttrs" :categoryName="info.categoryName" v-model:value="selectedSkuAttr"/>
                    <a-button type="primary" @click="addToCart" :disabled="disabled">
                        <span v-if="state.cartShake" class="dot-pulse"></span>
                        <span v-else>{{t('goodsDetail.addToCart')}}</span>
                    </a-button>
                </div>
                <div class="description">
                    <div class="title">
                        {{ Description }}
                    </div>
                    <a-divider/>
                    <div class="content">
                        <p v-for="item in info.descriptions" :key="item">{{item}}</p></div>
                </div>
                <div class="device-condition">
                    <div class="title">
                        {{t('goodsDetail.deviceConditionExplained')}}
                    </div>
                    <a-divider/>
                    <div class="content" v-for="item in explanation" :key="item">
                        <div class="grade">{{item.title}}</div>
                        <p class="explained">{{item.p}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="you-may-also-like" v-if="recommendList.length!==0">
            <div class="title">{{t('goodsDetail.youMayAlsoLike')}}</div>
            <div class="list">
                <index-goods-card
                    class="item"
                    v-for="item in recommendList"
                    :key="item.skuId"
                    :goodsId="item.goodsId"
                    :skuId="item.skuId"
                    :img="`${item.img.split(',')[0]}?x-oss-process=image/resize,w_175,h_165`"
                    :goodsName="item.name"
                    :originalPrice="item.originalPrice"
                    :currentPrice="item.price"
                    :goodsAttr="item.goodsAttr"
                    :realityStock="item.realityStock"
                />
                <!-- <index-goods-card
                    class="item"
                    v-for="item in recommendList"
                    :key="item.skuId"
                    :goodsId="item.goodsId"
                    :skuId="item.skuId"
                    :img="`${item.goodsPic}?x-oss-process=image/resize,w_175,h_165`"
                    :goodsName="item.goodsName"
                    :originalPrice="item.marketPrice"
                    :currentPrice="item.sellPrice"
                    :goodsAttr="item.goodsAttr"
                /> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import DetailCarousel from '@/components/goodsdetail/DetailCarousel.vue';
import importComponents from '@/utils/import-components';
import {
  computed,
  defineComponent, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { Button, Divider } from 'ant-design-vue';
import { Alert } from '@/components/Alert/index';
import SkuSelector from '@/components/SkuSelector/SkuSelector.vue';
import IndexGoodsCard from '@/components/index/IndexGoodsCard.vue';
import { getGoodsDetail, getGoodsRecommend } from '@/services/goods-detail';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import type { Attrs, GoodsRecommend } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
import { AddCart } from '@/services/interface/request';

type SelectValue = {
  target: {
    value: string;
  };
}

interface Info {
  album: string[];
  cover: string;
  categoryName: string;
  descriptions: string[];
  skuAttrs: Attrs[];
  albumOfColor: Record<string, string>;
}

export default defineComponent({
  name: 'GoodsDetail',
  components: {
    DetailCarousel,
    SkuSelector,
    IndexGoodsCard,
    Load,
    ...importComponents(
      Divider,
      Button,
    ),
  },
  setup() {
    const { t } = useI18n();

    const route = useRoute();

    /** 当前商品的 SKU ID */
    const sid = computed(() => route.query.s as string);
    /** 当前商品的 ID */
    const gid = computed(() => route.params.goodsId as string);

    const { state, dispatch } = useStore();

    // 从 SKU Selector 组件中拿的选择数据
    const selectedSkuAttr = ref<Record<string, string>>({
      color: '',
      operador: '',
      capacidad: '',
      condición: '',
    });

    const selectedSkuValues = computed(() => {
      const source: string[] = [];
      const text: string[] = [];
      Object.entries(selectedSkuAttr.value).forEach(([key, value]) => {
        if (value) {
          source.push(value);
        }
        text.push(t(`attrs.value.${key}.${value}`));
      });
      return {
        source: source.join(' ').trim(),
        text: text.join(' '),
      };
    });

    const info = reactive<Info>({
      album: [],
      albumOfColor: {},
      cover: '',
      categoryName: '',
      descriptions: [],
      skuAttrs: [],
    });

    const recommendList = ref<GoodsRecommend[]>([]);

    const explanation = [
      {
        title: t('goodsDetail.explanation.title.t1'),
        p: t('goodsDetail.explanation.p.p1'),

      },
      {
        title: t('goodsDetail.explanation.title.t2'),
        p: t('goodsDetail.explanation.p.p2'),

      },
      {
        title: t('goodsDetail.explanation.title.t3'),
        p: t('goodsDetail.explanation.p.p3'),
      },
    ];

    const disabled = ref(false);

    const loading = ref(true);

    const getGoodsRecommendList = async (category: number) => {
      const params = {
        categoryId: category,
        pageNum: 1,
        pageSize: 10,
      };
      const res = await getGoodsRecommend(params);
      if (res.success) {
        recommendList.value = res.data.list;
      }
    };

    const goodsInfo = reactive({});

    const selectedPrice = reactive({
      marketPrice: 0,
      sellPrice: 0,
    });

    const skuMap = new Map();

    const detail = async () => {
      const res = await getGoodsDetail(parseInt(gid.value, 10));
      const { success, data } = res;
      if (!success || !data || data.skuList.length === 0) return;
      Object.assign(goodsInfo, data);
      const attrs: Attrs[] = [];
      // 每个地区后台返回来的数据顺序都不一样，让颜色图片排第一
      data.attrs.forEach((attr) => {
        if (attr?.value[0]?.picture !== undefined) {
          attrs.unshift(attr);
          return;
        }
        attrs.push(attr);
      });
      info.skuAttrs = attrs;
      info.categoryName = data.categoryName;

      // 设置 category
      getGoodsRecommendList(data.categoryId);

      /**
             * 颜色图片数据
             * hzgotb
             * 2021-02-27 21:05
             */
      const colors = info.skuAttrs.find((attr) => attr.name === 'color');
      if (colors) {
        info.albumOfColor = colors.value.reduce((result, color) => {
          Reflect.set(result, color.labelValue, color.picture);
          return result;
        }, {} as Record<string, string>);
      }

      info.album = data.goodsAlbum?.split(',');
      info.cover = data.goodsPic;
      info.descriptions = data.description?.split('\n');
      // 找到默认的 SKU
      const defaultSKU = data?.skuList.find((item) => item.skuId === parseInt(sid.value, 10)) || data?.skuList[0];
      if (defaultSKU) {
        Object.entries(defaultSKU.goodsAttr).forEach(([key, value]) => {
          selectedSkuAttr.value[key] = value;
        });
        // 初始化价格
        selectedPrice.marketPrice = defaultSKU.marketPrice;
        selectedPrice.sellPrice = defaultSKU.sellPrice;
      }
      data.skuList.forEach((item) => {
        const attribute = Object.values(item.goodsAttr).join(' ');
        const {
          skuId, marketPrice, sellPrice, realityStock,
        } = item;
        skuMap.set(attribute, {
          skuId, marketPrice, sellPrice, realityStock,
        });
      });
      loading.value = false;
    };

    watch(selectedSkuValues, (newValue) => {
      const { source } = newValue;
      if (!skuMap.get(source) || !skuMap.get(source).realityStock) {
        // Loading.loading();
        disabled.value = true;
      } else {
        selectedPrice.marketPrice = skuMap.get(source).marketPrice;
        selectedPrice.sellPrice = skuMap.get(source).sellPrice;
        disabled.value = false;
      }
    });

    const addToCart = () => {
      const { source } = selectedSkuValues.value;
      if (skuMap.get(source)) {
        const addSkuId = skuMap.get(source).skuId;
        if (!Cookies.get('token')) {
          // 如果是游客加购，判断本地存的购物车数量有没有超过库存量
          const inCartSku = (state.ShoppingCart as AddCart[]).find((item) => item.skuId === addSkuId);
          if (inCartSku && inCartSku.quantity + 1 > skuMap.get(source).realityStock) {
            const modal = Alert.error(t('shipping.overstockSku'));
            setTimeout(() => modal.destroy(), 2000);
            return;
          }
        }
        dispatch('ADDTOCART', {
          goodsId: parseInt(gid.value, 10),
          skuId: skuMap.get(source).skuId,
          realityStock: skuMap.get(source).realityStock,
          quantity: 1,
        });
        dispatch('changeCartShake').then(() => {
          disabled.value = true;
          setTimeout(() => {
            dispatch('changeCartShake');
            disabled.value = false;
          }, 2000);
        });
      }
    };

    onBeforeMount(() => {
      detail();
    });

    watch(sid, () => {
      if (sid.value) {
        detail();
      }
    });

    return {
      info,
      explanation,
      recommendList,
      getGoodsRecommendList,
      selectedSkuValues,
      disabled,
      selectedSkuAttr,
      goodsInfo,
      addToCart,
      selectedPrice,
      skuMap,
      loading,
      state,
      sid,
      t,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";
@import '@/assets/styles/variables.scss';

.detail-container {
    @include main-container();
    padding: 80px 28px;
    .top {
        display: flex;
        .left-image {
            width: 46%;
            padding-right: 3%;
        }
        .right-standard {
            font-size: 25px;
            font-weight: 800;
            color: $font-color;
            line-height: 30px;
            flex: 1;
            .above {
                .goods-name {
                    font-size: 25px;
                    font-weight: 800;
                    color: $font-color;
                    line-height: 30px;
                    margin-bottom:10px;
                }
                .chose {
                    font-size: 20px;
                    font-weight: 400;
                    color: $font-color-weak;
                    line-height: 60px;
                }
                .price {
                    font-size: 15px;
                    color: $font-color-weak;
                    line-height: 39px;
                    align-items: center;
                    display: flex;
                    font-weight: 500;
                    .original-price {
                        font-size: 26px;
                        font-weight: 400;
                        color: #969696;
                        text-decoration: line-through;
                    }
                    .current-price {
                        font-size: 32px;
                        font-weight: 800;
                        color: $theme-color;
                    }
                }
                .ant-divider-horizontal {
                    margin: 13px 0;
                }
                .carrier {
                    font-weight: 500;
                    color: $font-color-weak;
                    font-size: 15px;
                    .selected {
                        font-weight: $font-weight;
                        color: $font-color;
                        padding-left: 10px;
                    }
                }
                .ant-radio-button-wrapper {
                    margin: 0 27px 0 0;
                    &:hover {
                        color: $theme-color;
                    }
                    .select-btn:checked {
                        color: $theme-color;
                        border-color: $theme-color;
                        box-shadow: -1px 0 0 0 $theme-color;
                    }
                }
                .ant-btn-primary {
                    line-height: 44px;
                    height: 44px;
                    width: 318px;
                    border-radius: 4px;
                    background-color: $theme-color;
                    border-color: $theme-color;
                    font-size: 20px;
                    font-weight: bold;
                }
                .ant-btn-primary-disabled, .ant-btn-primary.disabled, .ant-btn-primary[disabled], .ant-btn-primary-disabled:hover, .ant-btn-primary.disabled:hover, .ant-btn-primary[disabled]:hover, .ant-btn-primary-disabled:focus, .ant-btn-primary.disabled:focus, .ant-btn-primary[disabled]:focus, .ant-btn-primary-disabled:active, .ant-btn-primary.disabled:active, .ant-btn-primary[disabled]:active, .ant-btn-primary-disabled.active, .ant-btn-primary.disabled.active, .ant-btn-primary[disabled].active {
                    background-color: $theme-color;
                    border-color: #d9d9d9;
                }
                .dot-pulse {
                    position: relative;
                    left: -9999px;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    background-color: #fff;
                    color: #fff;
                    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
                    animation: dotPulse 1.5s infinite linear;
                }
                @keyframes dotPulse {
                    0% {
                    box-shadow: 9984px 0 0 2px #fff, 9999px 0 0 0 #fff,
                        10014px 0 0 -5px #fff;
                    }
                    25% {
                    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 -5px #fff,
                        10014px 0 0 0 #fff;
                    }
                    50% {
                    box-shadow: 9984px 0 0 -5px #fff, 9999px 0 0 0 #fff,
                        10014px 0 0 2px #fff;
                    }
                    75% {
                    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 2px #fff,
                        10014px 0 0 0 #fff;
                    }
                    100% {
                    box-shadow: 9984px 0 0 2px #fff, 9999px 0 0 0 #fff,
                        10014px 0 0 -5px #fff;
                    }
                }
            }
            .description, .device-condition {
                padding-bottom: 39px;
                border-bottom: 2px solid #F5F5F5;
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    padding: 39px 0 15px;;
                }
                .ant-divider.ant-divider-horizontal {
                    min-width: unset;
                    width: 97px;
                    height: 3px;
                    margin: unset;
                    background-color: $theme-color;
                }
                .content {
                    font-size: 14px;
                    font-weight: 400;
                    color: $font-color-weak;
                    padding-top: 15px;
                    line-height: 17px;
                }
                .grade {
                    color: $theme-color;
                }
                .explained {
                    margin: unset;
                    padding-top: 15px;
                }
            }
        }
    }
    .you-may-also-like {
        padding: 123px 0 0;
        .title {
            font-size: 25px;
            font-weight: 800;
            color: $font-color;
            line-height: 30px;
            text-align: center;
            margin-bottom: 40px;
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .item {
                width: 18.4%;
                border-radius: 8px;
                border: 1px solid #e3e3e3;
                margin: 0 2% 27px 0;
                &:nth-child(5),
                &:nth-child(10) {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
