
import {
  defineComponent, onMounted, reactive, ref,
} from 'vue';

export default defineComponent({
  name: 'Swipe',
  props: {
    swipeOptions: {
      required: true,
      type: Array,
    },
    bottomTitle: {
      type: String,
    },
  },
  setup(props) {
    const n = ref(0);
    const rBtnActive = ref(true);
    const lBtnActive = ref(false);
    const imgs: string[] = reactive([]);
    const loading = ref(true);
    const btnFunc = (value: string) => {
      if (value === 'left') {
        rBtnActive.value = false;
        lBtnActive.value = true;
        n.value = n.value === 0 ? props.swipeOptions.length - 1 : n.value - 1;
      } else {
        lBtnActive.value = false;
        rBtnActive.value = true;
        n.value = n.value === props.swipeOptions.length - 1 ? 0 : n.value + 1;
      }
    };
    const preload = () => { // 轮播图预加载
      (props.swipeOptions as string[]).forEach((item) => {
        const img = new Image();
        img.src = item;
        img.onload = () => {
          loading.value = false;
          imgs.push(item);
        };
      });
    };
    onMounted(() => {
      preload();
    });
    return {
      n,
      rBtnActive,
      lBtnActive,
      btnFunc,
      imgs,
      loading,
    };
  },
});
