
import { cloneDeep } from 'lodash';
import type { Attrs } from '@/services/interface/response';
import { Button, Modal } from 'ant-design-vue';
import {
  computed,
  defineComponent, ref,
} from 'vue';
import { Alert } from '@/components/Alert/index';
import { useI18n } from 'vue-i18n';
import mock from './mock';
import PopAlert from '../common/PopAlert.vue';
import Swipe from '../common/Swipe.vue';
import gradeImgList from './gradeImgs';

export interface Selected {
  [title: string]: string;
}

export default defineComponent({
  components: {
    PopAlert,
    Swipe,
    [Button.name]: Button,
    [Modal.name]: Modal,
  },
  props: {
    options: {
      required: true,
      type: Array as () => Attrs[],
    },
    value: {
      required: true,
      type: Object as () => Record<string, string>,
    },
    categoryName: {
      type: String,
    },
  },
  setup(props, ctx) {
    const { t } = useI18n();
    const gradeImgName = ref(props.value.grade); // 等级图等级
    const toUpperCase = (label: string) => label.replace(/^(\w)/, (word) => word.toUpperCase());

    const onClick = (name: string, selected: string) => {
      const newValue = cloneDeep(props.value);
      // TODO 修改对应的值
      newValue[name] = selected;
      ctx.emit('update:value', newValue);
      // eslint-disable-next-line no-unused-expressions
      name === 'grade' ? gradeImgName.value = selected : ''; // 设置等级轮播图标题
    };

    // 商品等级图弹框
    const gradeImgVisible = ref(false);

    // 等级图显示
    const imgs = computed(() => {
      const categoryImgs = gradeImgList.filter((item) => item.categoryName === props.categoryName)[0];
      const gradeImgs = categoryImgs.gradeImgs.filter((i) => i.grade === gradeImgName.value)[0];
      if (!gradeImgs) {
        return undefined;
      }
      return gradeImgs.imgs;
    });

    const gradeImgToggle = () => {
      if (!imgs.value) { // 解决没有选中grade时点击等级图按钮 弹框不弹出；
        const modal = Alert.error('Please choose grade');
        setTimeout(() => {
          modal.destroy();
        }, 2000);
        return;
      }
      gradeImgVisible.value = !gradeImgVisible.value;
      document.body.style.overflow = 'hidden';// 弹框出现 底部页面不可以滚动
    };

    return {
      mock,
      onClick,
      toUpperCase,
      gradeImgVisible,
      gradeImgToggle,
      imgs,
      gradeImgName,
      t,
    };
  },
});
